import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import ErrorBoundary from "../ErrorBoundary";
import { getToken } from "../../api/zendeskApi";
import { selectProfile } from "../../redux/ducks/appDuck";
import { authService, logErrorsService } from "../../core";

const ZENDESK_KEY = "214c9204-4b2d-4279-976a-8e0039539723";

const setting = {
  webWidget: {
    zIndex: 1200,
    color: {
      theme: "#2c11a3",
      launcherText: "#ffffff",
      header: "#1cf2e2",
    },
    helpCenter: {
      searchPlaceholder: {
        "*": "Search our Help Center",
      },
      title: {
        "en-US": "Search for help",
      },
    },
    chat: {
      suppress: false,
      notifications: {
        mobile: {
          disable: true,
        },
      },
      title: {
        "*": "Chat with us!",
      },
    },
  },
};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const callback = async () => {
  try {
    await sleep(2000);
    var style = document.createElement("style");
    style.innerHTML = `
          .ijUcZt {
              background-color: transparent !important;
          }
          .avatar-3raPF {
              background-color: transparent !important;
          }
      `;
    document.getElementById("webWidget").contentDocument.head.appendChild(style);
  } catch (err) {}
};

const WidgetZendesk = () => {
  const [loaded, setLoaded] = React.useState(false);
  const [authAdded, setAuthAdded] = React.useState(false);
  const profile = useSelector(selectProfile);
  const currentCompanyId = profile?.conum;
  const companyName = profile?.companyName;
  const conumRef = useRef(currentCompanyId);
  const coNameRef = useRef(companyName);
  const loadedRef = useRef(loaded);
  const { pathname } = useLocation();

  const setChatCoInfo = async () => {
    if (conumRef.current && loadedRef.current) {
      const tag = `Co# ${conumRef.current} - ${coNameRef.current}`;
      window?.zE("webWidget", "chat:addTags", [tag]);

      const url = `https://paydeck.brandspaycheck-${conumRef.current}.com${window?.location.pathname}/#${conumRef.current}`;
      const title = `Co# ${conumRef.current} - ${coNameRef.current}`;
      window?.zE("webWidget", "updatePath", {
        url: url,
        title: title,
      });
    }
  };

  const handleScriptLoaded = () => {
    setLoaded(true);
    window?.zE("webWidget:on", "open", callback);
    window?.zE("webWidget:on", "open", setChatCoInfo);
    window?.zE("webWidget:on", "chat:start", setChatCoInfo);
    window?.zE("webWidget:on", "chat:connected", setChatCoInfo);
    window?.zE("webWidget:on", "userEvent", callback);
  };

  React.useEffect(() => {
    if (loaded) return;
    const script = document.createElement("script");
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;
    script.defer = true;
    script.id = "ze-snippet";
    script.addEventListener("load", handleScriptLoaded);
    document.body.appendChild(script);
    window.zESettings = setting;
    return () => {
      document.body.removeChild(script);
    };
  }, [loaded, currentCompanyId]);

  React.useEffect(() => {
    if (loaded && authAdded) window?.zE("webWidget", "chat:reauthenticate");
  }, [loaded && authAdded]);

  React.useEffect(() => {
    conumRef.current = currentCompanyId;
    coNameRef.current = companyName;
    loadedRef.current = loaded;
    if (loaded && currentCompanyId && !authAdded && authService.isCompanyAuthenticated()) {
      window?.zE("webWidget", "updateSettings", {
        webWidget: {
          authenticate: {
            chat: {
              jwtFn: (callback) => {
                getToken()
                  .then((result) => {
                    callback(result);
                  })
                  .catch((ex) => {
                    logErrorsService.error("error in get zendesk chat token.", ex);
                    setAuthAdded(false);
                  });
              },
            },
          },
        },
      });
      setAuthAdded(true);
    } else if (loaded && !currentCompanyId && authAdded) {
      window?.zE("webWidget", "logout");
      window?.zE("webWidget", "updateSettings", {
        webWidget: {
          authenticate: undefined,
        },
      });
      setAuthAdded(false);
    }
  }, [currentCompanyId, loaded, authAdded]);

  React.useEffect(() => {
    if (currentCompanyId && loaded) {
      setChatCoInfo();
    }
  }, [currentCompanyId, companyName, loaded]);

  React.useEffect(() => {
    if (!loaded) return;
    if (pathname === "/swipe-clock") {
      window?.zE("webWidget", "hide");
    } else if (pathname.startsWith("/ess/")) {
      window?.zE("webWidget", "hide");
    } else {
      window?.zE("webWidget", "show");
    }
  }, [pathname, loaded]);

  return null;
};

const WrappedWidgetZendesk = () => {
  return (
    <ErrorBoundary noFallback>
      <WidgetZendesk />
    </ErrorBoundary>
  );
};

export default WrappedWidgetZendesk;
