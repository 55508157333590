import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

/**
 * Utils
 */
import { PAGES } from "./pages";
import ProtectedRoute from "./ProtectedRoute";
import ErrorBoundary from "../containers/ErrorBoundary";

import { selectInitiated, selectProfile } from "../redux/ducks/appDuck";
import { REDIRECT_TO } from "../utils/constants";
import SuperAdminRoutes from "../pages/internal/super_admin/SuperAdminRoutes";
import { sessionStorageSyncApi } from "../core/sessionStorageSyncApi";
import { CpaRoutes } from "./CpaRoutes";
import PayrollRoutes from "./PayrollRoutes";
import { EssRoutes } from "./EssRoutes";
import { DevextremeRoutes } from "./DevextremeRoutes";
import FrillSso from "../pages/auth/frillSso";

/**
 * Containers/Components (do not render a route)
 */
const LayoutMain = React.lazy(() => import("../containers/LayoutMain/LayoutMain"));
const LoadingScreen = React.lazy(() => import("../components/LoadingScreen"));
const EmployeeForm = React.lazy(() => import("../containers/EmployeeForm"));

/**
 * Auth routes -> /auth
 */
const PageBrandsLogin = React.lazy(() => import("../pages/auth/login"));
const PageBrands2FA = React.lazy(() => import("../pages/auth/login/mfa"));
const PageBrands2FAMethods = React.lazy(() => import("../pages/auth/login/mfa/methods"));
const PageConfirmEmail = React.lazy(() => import("../pages/auth/reset-password/email"));
const PageConfirmPassword = React.lazy(() => import("../pages/auth/set-up-account/password"));
const PageConfirmPhone = React.lazy(() => import("../pages/auth/set-up-account/phone"));
const PageFlowComplete = React.lazy(() => import("../pages/auth/set-up-account/complete"));
const PageSelectCompanyContainer = React.lazy(() => import("../pages/auth/select-company"));
const Impersonate = React.lazy(() => import("../pages/auth/logout/[guid]"));
/**
 * Dashboard routes -> /dashboard
 */
const PageDashboard = React.lazy(() => import("../pages/dashboard"));
/**
 * Reports routes -> /reports
 */
const PageReports = React.lazy(() => import("../pages/reports"));
const PageReportsQB = React.lazy(() => import("../pages/reports/qb"));
const PageReportsSectionPosted = React.lazy(() => import("../pages/reports/posted"));
const PageReportsSection = React.lazy(() => import("../pages/reports/[sectionId]"));
const PageReportsRunV2 = React.lazy(() => import("../pages/reports/run-v2"));
const PageReportsRunV2Section = React.lazy(() => import("../pages/reports/run-v2/[sectionId]"));
const PageLiveReports = React.lazy(() => import("../pages/reports/live"));
const PageReportsLive = React.lazy(() => import("../pages/reports/live/[reportId]"));
/**
 * Settings routes -> /settings
 */
const SettingsMain = React.lazy(() => import("../pages/settings"));
const SettingsUsers = React.lazy(() => import("../pages/settings/users"));
const SettingsManageCPAPortal = React.lazy(() => import("../pages/settings/cpaportal"));
const SettingsNotifications = React.lazy(() => import("../pages/settings/notifications"));
const PageEmployeeDeckSettings = React.lazy(() => import("../pages/settings/employeedeck"));
/**
 * Onboarding routes -> /onboarding
 */
const PageOnboarding = React.lazy(() => import("../pages/onboarding/[step]"));
const PageOnboardingNew = React.lazy(() => import("../pages/onboarding/new"));
const PageOnboardingSuccess = React.lazy(() => import("../pages/onboarding/success"));

/**
 * Other Routes
 */
const PageRestricted = React.lazy(() => import("../pages/restricted"));
const TestReactErrorBoundaries = React.lazy(() => import("../pages/test_react_error_boundaries"));
const AuthorizePayroll = React.lazy(() => import("../pages/authorize-payroll"));
const DebugView = React.lazy(() => import("../pages/debug"));
const QboConnect = React.lazy(() => import("../pages/qbo/connect"));
const PageEmployeeDeck = React.lazy(() => import("../pages/ess-invites"));
const PagePowerImports = React.lazy(() => import("../pages/powerImports"));
const PagePayrollFinancing = React.lazy(() => import("../pages/payroll-financing"));
const PageEmployees = React.lazy(() => import("../pages/employees"));
const PageNewEmployee = React.lazy(() => import("../pages/employees-new"));
const PageMultiCompanyUsers = React.lazy(() => import("../pages/usersbycompany"));
const PageServices = React.lazy(() => import("../pages/services"));
const PagePowergrids = React.lazy(() => import("../pages/powergrids"));
const PagePowergridsDetails = React.lazy(() => import("../pages/powergrids/[id]"));
const PagePowergridsNew = React.lazy(() => import("../pages/powergrids/new"));

const RootDiv = (props) => {
  const location = useLocation();
  const { children, ...rest } = props;

  return (
    <div {...rest} className={location.pathname !== "/login" ? "App mainBody" : "App"}>
      {children}
    </div>
  );
};

export const Routes = (props) => {
  const profile = useSelector(selectProfile);
  const initiated = useSelector(selectInitiated);
  const permissions = profile ? profile.permission : {};
  const conum = profile?.conum;
  const selectedCpaFirmId = profile?.selectedCpaFirmId;
  const history = useHistory();
  const native = queryString.parse(history.location.search)?.native;

  useEffect(() => {
    try {
      const { conum: sessionConum, empnum } =
        JSON.parse(sessionStorageSyncApi.getEmployeeId()) ?? {};
      if (empnum && sessionConum && conum && sessionConum === conum) {
        sessionStorageSyncApi.removeEmployeeId();
        history.push(`/employees/${empnum}/employeePersonalInfo`);
      }
    } catch (err) {
      console.log(err);
    }
  }, [conum]);

  useEffect(() => {
    if (native === "true") {
      sessionStorageSyncApi.setIsNative();
    }
  }, [native]);

  if (!initiated) return <LoadingScreen />;

  return (
    <RootDiv>
      <Suspense {...props} fallback={<LoadingScreen />}>
        <Switch>
          <Route exact path={PAGES.reportsPrimary.path}>
            <Redirect to={PAGES.reports.path} />
          </Route>
          <Route exact path="/debug" component={() => <DebugView />} />
          <Route path="/internal/super_admin" component={() => <SuperAdminRoutes />} />
          {/* <SuperAdminLayout /> */}

          <Route exact path="/" component={() => <Redirect to={PAGES.brandsLogin.path} />} />

          <Route exact path={`${PAGES.impersonate.path}/:guid`} component={Impersonate} />
          <ProtectedRoute
            exact
            requiredRedirectTo={REDIRECT_TO.twoFactorAuth}
            path={PAGES.twoFactorAuthLogin.path}
            userOnlyAuthenticated
            component={PageBrands2FA}
          />
          <ProtectedRoute
            exact
            requiredRedirectTo={REDIRECT_TO.twoFactorAuth}
            path={PAGES.twoFactorAuthMethods.path}
            component={PageBrands2FAMethods}
          />
          <Route path={PAGES.brandsLogin.path} component={PageBrandsLogin} />
          <Route path="/auth/frillSso" component={FrillSso} />
          <Route path={`${PAGES.onboarding.path}/new`} component={PageOnboardingNew} />
          <Route path={`${PAGES.onboarding.path}/success`} component={PageOnboardingSuccess} />
          <Route
            exact
            path={PAGES.brandsLoginSelectCompany.path}
            component={PageSelectCompanyContainer}
          />
          <Route
            exact
            path={PAGES.setUpAccountPhone.path}
            render={(props) => <PageConfirmPhone {...props} />}
          />
          <Route
            exact
            path={PAGES.setUpAccountPassword.path}
            render={(props) => <PageConfirmPassword {...props} />}
          />
          <Route
            exact
            path={PAGES.setUpAccountComplete.path}
            render={(props) => <PageFlowComplete {...props} />}
          />
          <Route exact path={PAGES.resetPasswordEmail.path} component={PageConfirmEmail} />
          <Route
            exact
            path={PAGES.resetPasswordPhone.path}
            render={(props) => <PageConfirmPhone {...props} flow="reset" />}
          />
          <Route
            exact
            path={PAGES.resetPasswordInput.path}
            render={(props) => <PageConfirmPassword {...props} flow="reset" />}
          />
          <Route
            exact
            path={PAGES.resetPasswordComplete.path}
            render={(props) => <PageFlowComplete {...props} flow="reset" />}
          />
          <Route
            exact
            path={PAGES.testReactErrorBoundaries.path}
            component={TestReactErrorBoundaries}
          />
          <ProtectedRoute
            exact
            path={PAGES.restrictedIp.path}
            userOnlyAuthenticated
            permission
            component={PageRestricted}
          />
          <Route exact path={PAGES.authorizePayroll.path} component={AuthorizePayroll} />
          <Redirect path={PAGES.standardReportsOld.path} to={PAGES.reportsPrimary.path} />
          <Redirect path={PAGES.runReportOld.path} to={PAGES.reportsRunV2.path} />
          <Redirect path={PAGES.reportsRun.path} to={PAGES.reportsRunV2.path} />
          <Redirect path={PAGES.myReportsOld.path} to={PAGES.reportsPosted.path} />

          <LayoutMain>
            <Suspense {...props} fallback={<LoadingScreen />}>
              <Switch>
                <ProtectedRoute
                  path={PAGES.dashboard.path}
                  permission={permissions?.dashboard}
                  component={PageDashboard}
                  disabled={!conum}
                />
                <ProtectedRoute
                  exact
                  path={PAGES.platinumPayExpress.path}
                  title={PAGES.platinumPayExpress.title}
                  permission={permissions?.managePayroll}
                  disabled={!conum}
                />
                <ProtectedRoute
                  exact
                  path={PAGES.swipeClock.path}
                  title={PAGES.swipeClock.title}
                  permission={profile?.swipeClockLoginName}
                  disabled={!conum}
                />
                <ProtectedRoute
                  exact
                  path={PAGES.hrNextDirect.path}
                  title={PAGES.hrNextDirect.title}
                  permission={permissions?.managePayroll}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={PAGES.payrollFinancing.path}
                  title={PAGES.payrollFinancing.title}
                  permission={permissions?.payrollFunding}
                  component={PagePayrollFinancing}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={`${PAGES.employees.path}/:empnum/:section`}
                  title={PAGES.employeeForm.title}
                  permission={permissions?.employee}
                  render={(props) => {
                    return (
                      <>
                        <EmployeeForm {...props} />
                        <PageEmployees {...props} />
                      </>
                    );
                  }}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={`${PAGES.employees.path}/:empnum`}
                  title={PAGES.employeeForm.title}
                  permission={permissions?.employee}
                  render={(props) => {
                    return (
                      <>
                        <EmployeeForm {...props} />
                        <PageEmployees {...props} />
                      </>
                    );
                  }}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={PAGES.employees.path}
                  title={PAGES.employees.title}
                  permission={permissions?.employee}
                  render={(props) => {
                    return (
                      <>
                        <EmployeeForm {...props} />
                        <PageEmployees {...props} />
                      </>
                    );
                  }}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={`${PAGES.liveReports.path}/:reportId`}
                  exact
                  title={PAGES.liveReports.title}
                  permission={permissions?.runReports}
                  component={PageReportsLive}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={PAGES.liveReports.path}
                  title={PAGES.liveReports.title}
                  permission={permissions?.runReports}
                  component={PageLiveReports}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={PAGES.reportsRunV2.path}
                  title={PAGES.reportsRunV2.title}
                  permission={permissions?.runReports}
                  component={PageReportsRunV2}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={PAGES.reportsPosted.path}
                  title={PAGES.reportsPosted.title}
                  permission
                  component={PageReportsSectionPosted}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={`${PAGES.reports.path}/qb`}
                  title={PAGES.reports.title}
                  permission
                  component={PageReportsQB}
                />
                <ProtectedRoute
                  path={`${PAGES.reports.path}/:sectionId`}
                  title={PAGES.reports.title}
                  permission
                  component={PageReportsSection}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={PAGES.reports.path}
                  title={PAGES.reports.title}
                  permission
                  component={PageReports}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={PAGES.powerImports.path}
                  title={PAGES.powerImports.title}
                  permission={permissions?.powerImports}
                  component={PagePowerImports}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={PAGES.essInvites.path}
                  title={PAGES.essInvites.title}
                  permission={permissions?.manageEmployeeDeck}
                  component={PageEmployeeDeck}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={PAGES.employeeDeckSettings.path}
                  title={PAGES.employeeDeckSettings.title}
                  permission={permissions?.manageEmployeeDeck}
                  component={PageEmployeeDeckSettings}
                  disabled={!conum}
                />
                <Redirect path={PAGES.employeeDeckUsers.path} to={PAGES.essInvites.path} />
                <ProtectedRoute
                  path={PAGES.settingsMain.path}
                  title={PAGES.settingsMain.title}
                  permission={permissions?.manageContactsAndUsers}
                  component={SettingsMain}
                  exact
                  disabled={!conum}
                />

                <ProtectedRoute
                  path={PAGES.settingsUsers.path}
                  title={PAGES.settingsUsers.title}
                  permission={permissions?.manageContactsAndUsers}
                  component={SettingsUsers}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={PAGES.settingsManageCpaPortal.path}
                  title={PAGES.settingsManageCpaPortal.title}
                  permission={permissions?.manageContactsAndUsers}
                  component={SettingsManageCPAPortal}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={PAGES.settingsNotifications.path}
                  title={PAGES.settingsNotifications.title}
                  permission
                  component={SettingsNotifications}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={PAGES.services.path}
                  title={PAGES.services.title}
                  permission={permissions?.services}
                  component={PageServices}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={`${PAGES.powergrids.path}/new`}
                  title={PAGES.powergrids.title}
                  // permission={permissions?.runReports}
                  permission
                  component={PagePowergridsNew}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={`${PAGES.powergrids.path}/:id`}
                  title={PAGES.powergrids.title}
                  // permission={permissions?.runReports}
                  permission
                  component={PagePowergridsDetails}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={PAGES.powergrids.path}
                  title={PAGES.powergrids.title}
                  permission
                  // permission={permissions?.services}
                  component={PagePowergrids}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={`${PAGES.reportsRunV2.path}/:sectionId`}
                  title={PAGES.reportsRunV2.title}
                  permission={permissions?.runReports}
                  component={PageReportsRunV2Section}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={PAGES.newEmployees.path}
                  title={PAGES.newEmployees.title}
                  permission={permissions?.employee}
                  component={PageNewEmployee}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={PAGES.usersbyCompany.path}
                  title={PAGES.usersbyCompany.title}
                  permission={permissions?.manageContactsAndUsers}
                  component={PageMultiCompanyUsers}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={`${PAGES.onboarding.path}/:step/:empnum/:section`}
                  title={PAGES.onboarding.title}
                  permission
                  component={PageOnboarding}
                  disabled={!conum}
                />
                <ProtectedRoute
                  path={`${PAGES.onboarding.path}/:step`}
                  title={PAGES.onboarding.title}
                  permission={profile?.redirectTo === REDIRECT_TO.onboarding}
                  component={PageOnboarding}
                  disabled={!conum}
                />
                <Route
                  path="/payrolls"
                  render={() => <PayrollRoutes permissions={permissions} />}
                />
                <Route
                  path="/cpa"
                  render={() => (
                    <CpaRoutes selectedCpaFirmId={selectedCpaFirmId} permission={permissions} />
                  )}
                />
                <Route path="/ess" render={() => <EssRoutes />} />
                <Route path="/devextreme" render={() => <DevextremeRoutes />} />
                <ProtectedRoute
                  path={PAGES.qboConnect.path}
                  title={PAGES.qboConnect.title}
                  permission={permissions?.qbo}
                  component={QboConnect}
                />

                <Redirect to={PAGES.dashboard.path} />
              </Switch>
            </Suspense>
          </LayoutMain>
        </Switch>
      </Suspense>
    </RootDiv>
  );
};

const WrappedRoutes = (props) => {
  return (
    <ErrorBoundary>
      <Routes {...props} />
    </ErrorBoundary>
  );
};

export default WrappedRoutes;
